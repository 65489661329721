import {
    IconBrandAmazon,
    IconShirtFilled,
    IconPackageExport,
    IconCpu,
    IconShoppingBag,
    IconGiftFilled,
    IconPerfume,
    IconDiamondFilled,
    IconStarsFilled,
    IconSmoking,
    IconBrush,
    IconCarFilled,
    IconBabyCarriageFilled,
    IconReportMoney,
    IconContract,
    IconBuildingStore,
    IconCandleFilled,
    IconCannabis,
    IconDeviceMobile,
    IconTagMinus,
    IconLegoFilled,
    IconDeviceDesktop,
    IconNeedleThread,
    IconHomeDollar,
    IconTie,
    IconBaguette,
    IconLuggage,
    IconChristmasTree,
    IconHomeFilled,
    IconSword,
    IconPlant,
    IconBriefcaseFilled,
    IconMilitaryRankFilled,
    IconRobot,
    IconSchool,
    IconConfetti,
    IconDog,
    IconShovelPitchforks,
    IconSpeakerphone,
    IconGlobeFilled,
    IconBuildingChurch,
    IconShieldFilled,
    IconShoe,
    IconPlayCard,
    IconBallAmericanFootball,
    IconBuildingWarehouse,
    IconSunglasses,
    IconBuildingHospital,
    IconHammer,
    IconHorseToy,
    IconBongFilled,
} from '@tabler/icons-react'

export const iconMapper: {
    [category: string] : JSX.Element
} = {
    "Amazon FBA Sellers": <IconBrandAmazon />,
    "Apparel / Clothing": <IconShirtFilled />,
    "Dropshippers": <IconPackageExport />,
    "Electronics": <IconCpu />,
    "General Merchandise": <IconShoppingBag />,
    "Gifts": <IconGiftFilled />,
    "Health, Beauty & Wellness": <IconPerfume />,
    "Jewelry": <IconDiamondFilled />,
    "Made in USA": <IconStarsFilled />,
    "Smoking Products": <IconSmoking />,
    "Art & Supplies": <IconBrush />,
    "Automotive": <IconCarFilled />,
    "Baby Items": <IconBabyCarriageFilled />,
    "Business Opportunities": <IconReportMoney />,
    "Business Services": <IconContract />,
    "C-Store Items": <IconBuildingStore />,
    "Candles, Oils, Incense": <IconCandleFilled />,
    "CBD": <IconCannabis />,
    "Cell Phone Accessories": <IconDeviceMobile />,
    "Closeout Suppliers": <IconTagMinus />,
    "Collectibles": <IconLegoFilled />,
    "Computer Products": <IconDeviceDesktop />,
    "Crafts & Supplies": <IconNeedleThread />,
    "Dollar Store": <IconHomeDollar />,
    "Fashion Accessories": <IconTie />,
    "Food & Grocery": <IconBaguette />,
    "Handbags & Luggage": <IconLuggage />,
    "Holiday & Seasonal": <IconChristmasTree />,
    "Housewares / Home Decor": <IconHomeFilled />,
    "Knives & Swords": <IconSword />,
    "Lawn & Garden": <IconPlant />,
    "Leather": <IconBriefcaseFilled />,
    "Military Goods": <IconMilitaryRankFilled />,
    "Novelties": <IconRobot />,
    "Office & School Supplies": <IconSchool />,
    "Party Supplies & Greeting Cards": <IconConfetti />,
    "Perfume": <IconPerfume />,
    "Pet Supplies": <IconDog />,
    "Professional Supplies": <IconShovelPitchforks />,
    "Promotional": <IconSpeakerphone />,
    "Regional & Ethnic": <IconGlobeFilled />,
    "Religious Items": <IconBuildingChurch />,
    "Self Defense, Security": <IconShieldFilled />,
    "Shoes & Footwear": <IconShoe />,
    "Souvenirs": <IconPlayCard />,
    "Sporting Goods": <IconBallAmericanFootball />,
    "Store / Dealer Supplies": <IconBuildingWarehouse />,
    "Sunglasses & Eyewear": <IconSunglasses />,
    "Survival & Emergency Kits": <IconBuildingHospital />,
    "Tools & Hardware": <IconHammer />,
    "Toys & Hobbies": <IconHorseToy />,
    "Vape Products / E-Liquids": <IconBongFilled />,
};
